import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Contact from "../../container/contact";
import SideHeader from "../../components/SideHeader";
import Header from "../../components/header/HeaderOne";
import HeaderTwo from "../../components/header/HeaderTwo";

import Footer from "../../components/footer/FooterThree";
import PersonalPortfolioTeam from "../../container/team";
import PersonalPortfolioService from "../../container/service";
import PersonalPortfolio from "../../container/portfolio/PersonalPortfolio";
import BannerPersonalPortfolio from "../../components/HeroBanner/PersonalPortfolio";

const HomePersonalPortfolio = () => {
  const history = useHistory();
  const code = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a', 'Enter'];
  const [userCode, setUserCode] = useState([]);

  const timerRef = useRef(null);

  useEffect(() => {

    const handleKeyPress = (e) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      const updatedUserCode = [...userCode, e.key];

      if (updatedUserCode.length > code.length) {
        updatedUserCode.shift();
      }
      if (updatedUserCode.length === code.length) {
        const isCorrect = updatedUserCode.every((value, index) => value === code[index]);
        if (isCorrect) {
          history.push('/99b1168c577fa4f3e2281972547fad0b');
          setUserCode([]);  // Reset userCode
          return;
        }
      }
      setUserCode(updatedUserCode);
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setUserCode([]);
      }, 1000);

    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [userCode]);


  return (

    <div className={'main-wrapper has-side-header'}>
      {window.innerWidth < 992 ? <Header classes={'position-static'}></Header>
        : <SideHeader mobile={false} />}
      {window.innerWidth < 992 && <SideHeader mobile={true} />}
      <BannerPersonalPortfolio />
      <PersonalPortfolio />
      <PersonalPortfolioService />
      <Contact />
      <Footer position={'static'} />
    </div>

  );
};

export default HomePersonalPortfolio;
