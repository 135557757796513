import React from 'react';
import ReactPlayer from 'react-player'
import Header from '../../components/header/HeaderOne';
import PageContainer from "../../container/CustomPage";
import vids from "../../data/vids/index.json";
import SideHeader from '../../components/SideHeader';
import ChildWrapper from './ChildWrapper';

const Page = () => {
    const reactPlayer = (value, index) => {
        return (
            <ReactPlayer key={value.id} playing url={`${value.url}`} className="portfolio-details-image mb-sm-30 mb-xs-30" />
        )
    }

    return (
        <PageContainer children={[vids.map(reactPlayer)]}>
        </PageContainer>
    )
}

export default Page